import { event } from "jquery";
import { IppicaActionsType} from './constants'

type IppicaAction = { type: string, payload: any };

export const addEventi = (evento: any): IppicaAction => {
  return {
    type: IppicaActionsType.ADD_EVENTI,
    payload: evento,
  }
};

export const addEvento = (evento: any, evento_id, quota_id, concessionario_codice, valore): IppicaAction =>{
  return {
    type: IppicaActionsType.ADD_EVENTO,
    payload: {
      evento: evento,
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      valore: valore
    }
  }
}

export const getEventi = (): IppicaAction => {
  return{
    type: IppicaActionsType.GET_EVENTI,
    payload: false,
  }
}

export const getEvento = (evento_id, quota_id, concessionario_codice, valore, valore_2): IppicaAction => {
  return {
    type: IppicaActionsType.GET_EVENTO,
    payload: {
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      valore: valore,
      valore_2: valore_2
    }
  }
}

export const addConcessionario = (concessionario: any): IppicaAction => {
  return {
    type: IppicaActionsType.ADD_CONCESSIONARI,
    payload: concessionario
  }
}

export const getConcessionari = (): IppicaAction => {
  return {
    type: IppicaActionsType.GET_CONCESSIONARI,
    payload: false,
  }
}

export const changeVisualizationConcessionari = (concessionario): IppicaAction => {
  return {
    type: IppicaActionsType.CHANGE_VISUALIZATION_CONCESSIONARIO,
    payload: concessionario
  }
}

export const changeVisualizationSpecialita = (specialita): IppicaAction => {
  return {
    type: IppicaActionsType.CHANGE_VISUALIZATION_SPECIALITA,
    payload: specialita
  }
}

export const changeVisualizationEventi = (campionato): IppicaAction => {
  return {
    type: IppicaActionsType.CHANGE_VISUALIZATION_EVENTI,
    payload: campionato
  }
}


export const changeVisualizationEventiAll = (checked): IppicaAction => {
  return {
    type: IppicaActionsType.CHANGE_VISUALIZATION_ALL_EVENTI,
    payload: checked
  }
}

export const changeTipoOrdinamento = (tipo): IppicaAction => {
  return {
    type: IppicaActionsType.CHANGE_TIPO_ORDINAMENTO,
    payload: tipo
  }
}


export const setQuota = (evento_id, quota_id, concessionario_codice, valore, valore_2): IppicaAction => {
  return {
    type: IppicaActionsType.SET_QUOTA,
    payload: {
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      valore: valore,
      valore_2: valore_2
    }
  
  }
}

export const setQuoteEvento = (quote, evento_id, errore_quote): IppicaAction => {
  return {
    type: IppicaActionsType.SET_QUOTA_EVENTO,
    payload: {
      quote: quote,
      evento_id: evento_id,
      errore_quote: errore_quote
    }
  }
}

export const getQuoteEvento = (evento_id, concessionari, specialita): IppicaAction => {
  return {
    type: IppicaActionsType.GET_QUOTE_EVENTO,
    payload:{
      evento_id: evento_id,
      concessionari: concessionari,
      specialita: specialita
    }
  }
}

export const addSpecialita = (specialita): IppicaAction => {
  return {
    type: IppicaActionsType.ADD_SPECIALITA,
    payload: specialita
  }
}

export const getSpecialita = (): IppicaAction =>{
  return{
    type: IppicaActionsType.GET_SPECIALITA,
    payload: false
  }
}

export const setToken = (token): IppicaAction => {
  return {
    type: IppicaActionsType.SET_TOKEN,
    payload: token 
  }
}

export const setCampionati = (eventi): IppicaAction => {
  return {
    type: IppicaActionsType.SET_CAMPIONATI,
    payload: eventi
  }
}

export const setDistance = (distance): IppicaAction => {
  return {
    type: IppicaActionsType.SET_DISTANCE,
    payload: distance
  }
}

export const checkAlertDistance = (): IppicaAction => {
  return {
    type: IppicaActionsType.CHECK_ALERT_DISTANCE,
    payload: null
  }
}

// QUOTA MEDIA NON PRESENTE PER L'IPPICA QUINDI NON UTILIZZATA 
// export const setPercentuale = (percentuale): IppicaAction => {
//   return {
//     type: IppicaActionsType.SET_PERCENTUALE,
//     payload: percentuale
//   }
// }


// export const checkQuotaMedia = (): IppicaAction => {
//   return {
//     type: IppicaActionsType.CHECK_ALERT_AVG_PERCENTUALE,
//     payload: null
//   }
// }

// Action non utilizzata valutare se cancellarla direttamente
// export const checkQuotaMediaEvento = (evento_id): IppicaAction => {
//   return {
//     type: IppicaActionsType.CHECK_EVENT_ALERT_AVG_PERCENTUALE,
//     payload: evento_id
//   }
// }

// export const checkQuotaMediaMarcatore = (quota_id, evento_id): IppicaAction => {
//   return {
//     type: IppicaActionsType.CHECK_MARCATORE_SPECIALITA_ALERT_AVG_PERCENTUALE,
//     payload: {
//       quota_id: quota_id,
//       evento_id: evento_id
//     }
//   }
// }


export const changeAlertSoundState = (): IppicaAction => {
  return {
    type: IppicaActionsType.CHANGE_STATE_ALERT_SOUND,
    payload: null
  }
}

export const deleteStartedEvents = (): IppicaAction => {
  return {
    type:IppicaActionsType.DELETE_STARTED_EVENT,
    payload: null
  }
}

export const setStateEnabledEvent = (evento, enabled_state): IppicaAction => {
  return {
    type: IppicaActionsType.SET_ENABLED_STATE_EVENT,
    payload: {
        evento: evento,
        enabled: enabled_state
    }
  }
}

export const setStateEnabledPlayer = (evento, competitor, enabled_state): IppicaAction => {
  return {
    type: IppicaActionsType.SET_ENABLED_STATE_COMPETITOR,
    payload: {
        evento: evento,
        competitor: competitor,
        enabled: enabled_state
    }
  }
}

export const setEyeState = (stato): IppicaAction => {
  return {
    type: IppicaActionsType.SET_EYE_STATE,
    payload: stato
  }
}

export const setAlertAbilitati = (alert_abilitati): IppicaAction => {
  return {
    type: IppicaActionsType.SET_ALERT_ABILITATI,
    payload: alert_abilitati
  }
}

export const setTipoFiltroData = (tipo): IppicaAction => {
  return {
    type: IppicaActionsType.SET_FILTRO_TIPO_DATA,
    payload: tipo
  }
}

export const setFiltroEvento = (filtro_evento): IppicaAction => {
  return {
    type: IppicaActionsType.SET_FILTRO_EVENTO_NAME,
    payload: filtro_evento
  }
}

export const setRefList = (ref): IppicaAction => {
  return {
    type: IppicaActionsType.SET_REF_LIST,
    payload: ref
  }
} 

export const setStateOpenCompetitor = (state, evento, competitor): IppicaAction => {
  return {
    type: IppicaActionsType.SET_OPEN_STATE_COMPETITOR,
    payload: {
      state: state,
      evento: evento,
      competitor: competitor
    }
  }
}

export const allSetStateOpenCompetitor = (state): IppicaAction => {
  return {
    type: IppicaActionsType.ALL_SET_OPEN_STATE_COMPETITOR,
    payload: state
  }
}

export const setStateOpenEvento = (state, evento): IppicaAction => {
  return {
    type: IppicaActionsType.SET_OPEN_STATE_EVENTO,
    payload: {
      state: state,
      evento: evento,
    }
  }
}

export const allSetStateOpenEvento = (state): IppicaAction => {
  return {
    type: IppicaActionsType.ALL_SET_OPEN_STATE_EVENTO,
    payload: state
  }
}

export const updateList = (): IppicaAction => {
  return {
    type: IppicaActionsType.UPDATE_LIST,
    payload: null,
  }
}

export const saveImpostazioni = (): IppicaAction => {
  return {
    type: IppicaActionsType.SAVE_IMPOSTAZIONI,
    payload: null,
  }
}

export const setFiltroCompetitor= (filtro_competitor): IppicaAction => {
  return {
    type: IppicaActionsType.SET_FILTRO_COMPETITOR,
    payload: filtro_competitor
  }
}

export const setSpecialitaEvento = (evento, specialita_id, attiva): IppicaAction => {
  return {
    type: IppicaActionsType.SET_SPECIALITA_EVENTO,
    payload: {
      evento: evento,
      specialita_id: specialita_id,
      attiva: attiva
    }
  }
}

export const setSpecialitaCampionato = (campionato, specialita_id, attiva): IppicaAction => {
  return {
    type: IppicaActionsType.SET_SPECIALITA_CAMPIONATO,
    payload: {
      campionato: campionato,
      specialita_id: specialita_id,
      attiva: attiva
    }
  }
}

export const setVisualizzaQuotePresenti = (checked): IppicaAction => {
  return {
    type: IppicaActionsType.SET_VISUALIZZA_QUOTE_PRESENTI,
    payload: checked
  }
}

export const setQuotaDescrizione = (evento_id, quota_id, concessionario_codice, descrizione): IppicaAction => {
  return {
    type: IppicaActionsType.SET_QUOTA_DESCRIZIONE,
    payload: {
      evento_id: evento_id,
      quota_id: quota_id,
      concessionario_codice: concessionario_codice,
      descrizione: descrizione
    }
  }
}

export const setDistancePunta = (distance_punta): IppicaAction => {
  return {
    type: IppicaActionsType.SET_DISTANCE_PUNTA,
    payload: distance_punta
  }
}

export const checkAlertDistancePunta = (): IppicaAction => {
  return {
    type: IppicaActionsType.CHECK_ALERT_DISTANCE_PUNTA,
    payload: null
  }
}

// Azione usata per salvare le impostazioni passate da 4bet
export const settings4bet = (settings): IppicaAction => {
  return {
    type: IppicaActionsType.SET_SETTINGS_4BET,
    payload: settings
  }
}
